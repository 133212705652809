<template>
  <div>
    <Header />
    <div class="md:h-480 h-96 w-full bg-black bg-opacity-30 relative">
      <img
        src="../assets/hero-about.png"
        alt="Hero About"
        class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
      />
      <div
        class="h-full w-full flex justify-center items-center text-center text-white font-bold"
      >
        <h1 class="md:text-7xl text-4xl">Karir di Tripwe</h1>
      </div>
    </div>
    <div class="md:flex flex-row gap-12 md:px-24 md:py-16 p-8">
      <div class="md:w-1/2 w-full flex flex-col gap-8">
        <h2 class="font-semibold">Join Our Team</h2>
        <div class="flex flex-col gap-4">
          <p>
            Apakah Anda suka bekerja di luar ruangan dengan orang lain dan
            dengan publik? Tripwe menawarkan beberapa posisi pekerjaan untuk
            orang seperti Anda!
          </p>
          <p>
            Tim kami bertujuan untuk menginspirasi dan memungkinkan orang untuk
            menjelajahi, mengalami, dan berbagi dunia dengan percaya diri. Kami
            beroperasi di bawah seperangkat nilai bersama yang menentukan cara
            kami melakukan bisnis dan cara kami berinteraksi dengan kolega,
            mitra, pelanggan, dan komunitas perjalanan kami.
          </p>
          <p>
            Kami percaya bahwa bersama-sama lebih baik, dan di Tripwe kami
            menyambut Anda dengan segala kelebihan yang Anda miliki. Tempat
            kerja kami adalah untuk semua orang, seperti halnya platform yang
            diberdayakan oleh karyawan kami. Kami ingin Anda membawa identitas,
            kemampuan, dan pengalaman unik Anda, sehingga kami dapat
            bersama-sama merevolusi perjalanan dan bersama-sama menemukan
            hal-hal menarik di luar sana.
          </p>
        </div>
        <div
          class="flex flex-col gap-4 md:p-8 p-4 bg-grey-1 border-l-2 border-grey-2"
        >
          <h3 class="font-semibold">SUBMIT YOUR CV</h3>
          <p>career@tripwe.com</p>
        </div>
      </div>
      <div class="md:w-1/2 w-full my-8 md:my-0">
        <VueSlickCarousel v-bind="settings">
          <div class="h-full">
            <img src="../assets/career-mobile.png" alt="" />
          </div>
          <div class="h-full">
            <img src="../assets/career-backend.png" alt="" />
          </div>
          <template #customPaging="page">
            <div class="custom-dot">
              <div class="hidden">{{ page }}</div>
            </div>
          </template>
        </VueSlickCarousel>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { Header, Footer, VueSlickCarousel },
  data: () => ({
    settings: {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      swipeToSlide: true,
      autoplay: true,
      dotsClass: "custom-slick-dots",
    },
  }),
};
</script>
